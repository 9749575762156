// TODO: Lodash should no longer be used here
// eslint-disable-next-line no-restricted-imports
import _ from 'lodash';

import {
  ROOMS_FETCHED,
  ROOMS_LISTENER_STARTED,
  ROOM_ADDED,
  ROOM_SELECTED,
  ROOM_SELECTION_CLEARED,
  ROOM_UPDATED,
} from './types';

import { organizationRoomsOnListen } from '../../api/firebase/rooms';

import { Room } from '@wonderschool/common-base-types';
import { getUsersRoomsOrLocations } from '../helpers/getUsersRoomsOrLocations';
import store from '../store';

export const roomsListenerStarted = () => ({
  type: ROOMS_LISTENER_STARTED,
});

export const roomsFetched = (rooms: Room[]) => ({
  type: ROOMS_FETCHED,
  rooms,
});

export const roomAdded = (room: Room) => ({
  type: ROOM_ADDED,
  room,
});

export const roomUpdated = (room: Partial<Room>) => ({
  type: ROOM_UPDATED,
  room,
});

export const roomSelected = (room?: Room) => ({
  type: ROOM_SELECTED,
  room,
});

export const roomSelectionCleared = () => ({
  type: ROOM_SELECTION_CLEARED,
});

export const getRoomById = (id: string) => {
  if (!id) return null;
  const rooms = store.getState().rooms.list || [];
  if (rooms) return _.find(rooms, { id });
};

export const getRooms = () => {
  const rooms = store.getState().rooms.list;
  if (_.isEmpty(rooms)) return null;
  return rooms || null;
};

export const startOrganizationRoomsListener = (organizationId: string) => (dispatch) => {
  dispatch(roomsListenerStarted());
  const validRoomIds = getUsersRoomsOrLocations('rooms');

  return organizationRoomsOnListen(
    { organizationId, validRoomIds },
    (data) => {
      if (data) dispatch(roomsFetched(data));
    },
    (error) => console.log(error.message)
  );
};
